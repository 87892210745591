/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    const Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                $('#btn_open_toast_menu').on('click', function() {
                    $("#toast_menu").show();
                });

                $('#btn_close_toast_menu').click('click', function() {
                    $("#toast_menu").hide();
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                var ppp = 9;
                var page = 1;

                function load_more_posts() {
                    page++;
                    $.ajax({
                        type: "POST",
                        dataType: "html",
                        url: ajax_posts.ajaxurl,
                        data: {
                            page: page,
                            ppp: ppp,
                            action: 'load_more_post_ajax'
                        },

                        success: function (data) {
                            if ($('.recent-post-section').length > 0) {
                                $('.recent-post-section > .post-group').append(data);
                            }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            console.log("Ajax Request Error");
                        }
                    });
                    return false;
                }

                if ($('#btn_load_more_posts').length > 0) {
                    $('#btn_load_more_posts').on('click', function (e) {
                        load_more_posts();
                    });
                }
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Category page
        'category': {
            init: function () {
                var ppp = 9;
                var page = 1;

                function load_more_posts(cat) {
                    page++;
                    $.ajax({
                        type: "POST",
                        dataType: "html",
                        url: ajax_posts.ajaxurl,
                        data: {
                            page: page,
                            ppp: ppp,
                            action: 'load_more_post_ajax',
                            cat: cat
                        },

                        success: function (data) {
                            if ($('.post-section').length > 0) {
                                $('.post-section > .post-group').append(data);
                            }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            console.log("Ajax Request Error");
                        }
                    });
                    return false;
                }

                if ($('#btn_load_more_posts').length > 0) {
                    $('#btn_load_more_posts').on('click', function (e) {
                        var cat = $('#btn_load_more_posts').data('category');
                        load_more_posts(cat);
                    });
                }
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Popular page
        'popular': {
            init: function () {
                var ppp = 9;
                var page = 1;

                function load_more_posts() {
                    page++;
                    $.ajax({
                        type: "POST",
                        dataType: "html",
                        url: ajax_posts.ajaxurl,
                        data: {
                            page: page,
                            ppp: ppp,
                            action: 'load_more_popular_post_ajax',
                        },

                        success: function (data) {
                            if ($('.post-section').length > 0) {
                                $('.post-section > .post-group').append(data);
                            }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            console.log("Ajax Request Error");
                        }
                    });
                    return false;
                }

                if ($('#btn_load_more_posts').length > 0) {
                    $('#btn_load_more_posts').on('click', function (e) {
                        load_more_posts();
                    });
                }
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Posts page
        'posts': {
            init: function () {
                var ppp = 9;
                var page = 1;

                function load_more_posts(author, tag) {
                    page++;
                    $.ajax({
                        type: "POST",
                        dataType: "html",
                        url: ajax_posts.ajaxurl,
                        data: {
                            page: page,
                            ppp: ppp,
                            author: author,
                            tag: tag,
                            action: 'load_more_post_ajax',
                        },

                        success: function (data) {
                            if ($('.post-section').length > 0) {
                                $('.post-section > .post-group').append(data);
                            }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            console.log("Ajax Request Error");
                        }
                    });
                    return false;
                }

                if ($('#btn_load_more_posts').length > 0) {
                    $('#btn_load_more_posts').on('click', function (e) {
                        var author = $('#btn_load_more_posts').data('author');
                        var tag = $('#btn_load_more_posts').data('tag');
                        load_more_posts(author, tag);
                    });
                }
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    const UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
